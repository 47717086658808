import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => $('.landing-hero').each((i, el) => {
    const $container = $(el);
    const src = $container.find('.vc-single-image img').data('src')
            || $container.find('.vc-single-image .lazy-image').data('lazyImage').src;
    if (src) {
        $container.prepend(
            $(
                `<div class="content-background-image"><div class="content-background-image__img" style="background-image: url('${src}')"></div></div>`,
            ),
        );
    }
}));
