import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(
    INIT,
    () => {
        const content = window.dynamicContent || [];
        const urlParams = new URLSearchParams(window.location.search);
        const match = content.find((item) => urlParams.getAll(item.key).includes(item.value));
        const $container = $('.landing-hero');
        if (!match) {
            $container.addClass('landing-hero--updated');
            return;
        }
        if (match.headline) {
            $container.find('h2').first().html(match.headline);
        }
        if (match.image) {
            const image = $('<span>');
            image.addClass('lazy-image vc-single-image__image');
            image.attr('data-lazy-image', JSON.stringify({ src: match.image }));
            $container.find('.vc-single-image__figure').html(image);
        }

        $container.addClass('landing-hero--updated');
    },
    5,
);
